import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import React from "react";
import styled from "styled-components";
import sortBy from 'lodash/sortBy'




const DraggableElement = ({ prefix, elements, makeInActive, isInActive, catLabel }) => {


	const HeaderEdit = ({ prefix, isInActive }) => {

		const reviseHeader = async (val, prefix) => {
			console.log('val', val, prefix);


			var data = new FormData();
			data.append('sendVal', JSON.stringify({ 'val': val, 'prefix': prefix }));
			data.append('act', 'update_catlabel');

			const mark = await fetch('https://panel.art-technology.org/db/index.php',
				{
					method: 'POST',
					mode: 'no-cors',
					headers: {
						'Accept': 'application/json, text/plain, */*',
						'Content-Type': 'application/json'
					},
					body: data
				}).then(response => {
					console.log('response', response);

				});



		}


		if (isInActive) {

			return (
				<h2 className='active'>


					<span
						className="can_edit"
						contentEditable={true}
						suppressContentEditableWarning={true}
						spellCheck="false"
						onBlur={e => { reviseHeader(e.currentTarget.textContent, prefix) }}

					>{catLabel[prefix] ?? '...'}
					</span>

					<span className='label_ref'>{prefix}</span>
				</h2>
			)
		} else {
			return (<h2 className='active'> {catLabel[prefix]} </h2>)
		}



	}

	return (

		<div className={isInActive === 0 ? 'list_scroll' : ''}>

			<HeaderEdit prefix={prefix} isInActive={isInActive} />
			<Droppable droppableId={`${prefix}`}>
				{(provided) => (
					<div {...provided.droppableProps} ref={provided.innerRef}>
						{elements.map((item, index) => (
							<ListItem key={item.id} item={item} index={index} makeInActive={makeInActive} isInActive={isInActive}
								className={'active'}

							/>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</div>
	)
};

export default DraggableElement;


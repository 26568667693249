import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableElement from "./DraggableElement";
import sortBy from 'lodash/sortBy'
import Nav from "./Nav";
import Modal from 'react-modal';

const DragDropContextContainer = styled.div`
  padding: 10px;
`;

const ListGrid = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px; */
`;

const customStyles = {
	content: {
		top: '20%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		position: 'relative',

		width: '300px',
		height: '150px',
	},
};

const removeFromList = (list, index) => {
	const result = Array.from(list);
	const [removed] = result.splice(index, 1);
	return [removed, result];
};

const addToList = (list, index, element) => {
	// element.status = 1;
	const result = Array.from(list);
	result.splice(index, 0, element);
	return result;
};

Modal.setAppElement('#root');

function DragList() {
	const [elements, setElements] = useState({});
	const [lists, setLists] = useState([])
	const [catLabel, setCatLabel] = useState({})

	const [modalIsOpen, setIsOpen] = useState({});

	const [allCues, setAllCues] = useState({})

	const formEl = useRef(null);

	const pullApi = async () => {

		try {
			const response = await fetch(
				'https://panel.art-technology.org/db/index.php?act=all',
			)

			let dataReturn = await response.json()
			let cats = Object.values(dataReturn.body.cats);

			// @ts-ignore
			setLists(Array.from(new Set(cats)))
			setCatLabel(dataReturn.body.cat_labels)

			let carrierObject = {};
			let cueObject = {};
			cats.forEach(cat => {
				carrierObject[cat] = [];
			})

			dataReturn.body.cues.forEach(item => {
				item.id = item.id.toString();
				carrierObject[item.cat_name].push(item);
				cueObject[item.id] = item.name;
				//allCues
			})

			setAllCues(cueObject)

			for (let cat of Object.keys(carrierObject)) {
				if (cat.substring(0, 2) === 'x_') {
					carrierObject[cat] = sortBy(carrierObject[cat], 'name');
				}
			}

			setElements(carrierObject);



		} catch (error) {
			console.log('error', error)
		}
	}

	useEffect(() => {
		pullApi();
	}, []);

	const updateSeq = async (newSeq) => {
		console.log('newSeq', newSeq);
		var data = new FormData();
		data.append('sendVal', JSON.stringify(newSeq));
		data.append('act', 'update_seq');

		const mark = await fetch('https://panel.art-technology.org/db/index.php',
			{
				method: 'POST',
				mode: 'no-cors',
				headers: {
					'Accept': 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				body: data
			}).then(response => {
				console.log('response', response);

			});
	}

	const onDragEnd = (result) => {

		if (!result.destination) {
			return;
		}
		const listCopy = { ...elements };

		const sourceList = listCopy[result.source.droppableId];
		const [removedElement, newSourceList] = removeFromList(
			sourceList,
			result.source.index
		);
		listCopy[result.source.droppableId] = newSourceList;
		const destinationList = listCopy[result.destination.droppableId];

		listCopy[result.destination.droppableId] = addToList(
			destinationList,
			result.destination.index,
			removedElement
		);

		let tempObject = {}

		for (let cat of Object.keys(listCopy)) {
			if (!tempObject[cat]) { tempObject[cat] = [] }

			for (let record of listCopy[cat]) {
				if (cat.substring(0, 2) === 'x_') {
					record.status = 0;
				} else {
					record.status = 1;
				}

				tempObject[cat].push(record);
			}
		}

		for (let cat of Object.keys(tempObject)) {
			if (cat.substring(0, 2) === 'x_') {
				tempObject[cat] = sortBy(tempObject[cat], 'name');
			}
		}

		setElements(tempObject);
		let newSeq = JSON.parse(JSON.stringify(listCopy))
		for (let cat of Object.keys(newSeq)) {
			newSeq[cat] = newSeq[cat].map(item => item.id)
		}

		updateSeq(newSeq) 
	};

	const makeInActive = async (id, origCat, e) => {

		if (e.metaKey) {
			const response = await fetch(
				'https://panel.art-technology.org/db/index.php?act=delete&id=' + id,
			)
			await pullApi();
			return;
		}

		if (e.shiftKey) {
			setIsOpen({ t: allCues[id], id: id })
		}
	}


	const procForm = async (e) => {
		const response = await fetch(
			'https://panel.art-technology.org/db/index.php?act=updateItem&id=' + formEl.current[1].value + '&name=' + encodeURI(formEl.current[0].value),
		)
		await pullApi();
		setIsOpen({})

	}


	const [filterQuery, setFilterQuery] = useState('')

	const startList = ["incoming"];
	const targList = ["A", "B", "C", "D", "E", "F", "G", "H"];

	return (
		<div className="container">
			<Nav />
			<DragDropContextContainer className="container_list">
			<DragDropContext onDragEnd={onDragEnd}>
					<div className="sort_lane">



						{startList.map((listKey) => (
							<DraggableElement
								elements={elements[listKey] ?? []}
								key={listKey}
								prefix={listKey}
								makeInActive={makeInActive}
								isInActive={0}
								catLabel={catLabel}
							/>
						))}
					</div>
					<div className="targ_lane">
						{targList.map((listKey) => (
							<DraggableElement
								elements={elements[listKey] ?? []}
								key={listKey}
								prefix={listKey}
								makeInActive={makeInActive}
								isInActive={1}
								catLabel={catLabel}
							/>
						))}
					</div>
			</DragDropContext>
			</DragDropContextContainer>


			<Modal isOpen={Object.keys(modalIsOpen).length > 0} style={customStyles} contentLabel="Example Modal" >
				<button className="close_button" onClick={() => setIsOpen({})}>&times;</button>
				<form ref={formEl}>
					<input type="text" defaultValue={modalIsOpen.t} className="modal_input" /><br />
					<input type="hidden" defaultValue={modalIsOpen.id} />

				</form>
				<button onClick={() => procForm()} className="modal_button">Revise</button>
			</Modal>

		</div>			
	);
}

export default DragList;
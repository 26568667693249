import { Link } from "react-router-dom";


function Nav() {
  return (
    <div className="Nav">
      <ul>
        <li><Link to="/enter">Enter</Link></li>
        <li><Link to="/">Organize</Link></li>
        <li><Link to="/print">Print</Link></li>

      </ul>

    </div>
  );
}

export default Nav;

import { Draggable } from "react-beautiful-dnd";

import styled, { css } from "styled-components";




// const lorem = new LoremIpsum();

const ListItem = ({ item, index, makeInActive, isInActive, className }) => {
	return (
		<Draggable draggableId={item.id} index={index}>
			{(provided, snapshot) => {
				return (
					<div className={`dragItem ${className}`}
						onClick={(e) => makeInActive(item.id, item.cat_name, e)}
						ref={provided.innerRef}
						snapshot={snapshot}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
					>
						<div className="itemdes">{item.name}</div>

					</div>
				);
			}}
		</Draggable>
	);
};

export default ListItem;
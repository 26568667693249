import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';

function Login() {
  const [cookies, setCookie] = useCookies();
  const cookiesr = new Cookies();
  const checklog = (e) => {
    if (e === '15crest') {
      let future = new Date(parseInt(Date.now()) + 100000000000)
      cookiesr.set('mlog', '15crest', { path: '/', expires: future });
      window.location.reload();
    }


  }
  return (
    <div className="Login">

      <input type="text" className="login_field" onChange={(e) => checklog(e.target.value)} />


    </div>
  );
}

export default Login;
